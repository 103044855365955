/* eslint-disable @typescript-eslint/typedef */
import { LocationStrategy } from '@angular/common';
import { APP_INITIALIZER, EnvironmentProviders, Provider } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { basicInit, basicInitDeps } from '@bcf-libs/app-utils/src/lib/basic-initializator';
import { cookiesFromUrlDeps, cookiesFromUrlInit } from '@bcf-libs/app-utils/src/lib/cookies-from-url-initializator';
import { routerInitAfterEnvSetup, routerInitAfterEnvSetupDeps } from '@bcf-libs/app-utils/src/lib/routes-initializator';
import { ModalMainContainerComponent } from '@bcf-libs/ui/container/components/casino/lvbetcom/web/src/lib/modal-main-container/modal-main-container.component';
import { BCF_ROUTER_PROVIDER } from '@bcf-libs/ui/shared/src/lib/bcf-router/bcf-router-factory';
import { MODAL_EMBEDDED_CONTAINER } from '@bcf-libs/ui/shared/src/lib/modal-embedded-container/modal-embeddec-container.token';
import { CustomPathLocationStrategy } from '@bcf-shared-platform/path-location-strategy/custom-path-location-strategy';
import { piqTheme } from '@bcf-v2-configurators/tokens/piq-styles/lvbetcom/piq-theme';
import { PIQ_THEME } from '@bcf-v2-configurators/tokens/piq-styles/token';
import { TrailingSlashUrlSerializer } from '@bcf-v2-utilities/app-utils/trailing-slash-url-serializer';
import { provideI18nTranslations } from '@bcf-v2-utilities/i18n-translations/i18n-translations-provider';
import { settingsProviders } from './settings-providers';

const appCommonProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: basicInit,
    deps: basicInitDeps,
    multi: true
  },
  provideI18nTranslations(),
  {
    provide: APP_INITIALIZER,
    useFactory: cookiesFromUrlInit,
    deps: cookiesFromUrlDeps,
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: routerInitAfterEnvSetup,
    deps: routerInitAfterEnvSetupDeps,
    multi: true
  },
  BCF_ROUTER_PROVIDER,
  ...settingsProviders,
  { provide: UrlSerializer, useClass: TrailingSlashUrlSerializer },
  { provide: LocationStrategy, useClass: CustomPathLocationStrategy },
  { provide: MODAL_EMBEDDED_CONTAINER, useValue: ModalMainContainerComponent },
  { provide: PIQ_THEME, useValue: piqTheme }
];

export const appBrowserProviders: (Provider | EnvironmentProviders)[] = [...appCommonProviders];

export const appServerProviders: (Provider | EnvironmentProviders)[] = [...appCommonProviders];
