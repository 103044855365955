import { casinoGamesRulesRoutePath } from '@bcf-v2/routes-landings/common/app/paths/casino-games-rules-route-path';
import { mobileApplicationRoutePath } from '@bcf-v2/routes-landings/common/app/paths/mobile-application-route-path';
import { aboutUsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/about-us-route-path';
import { privacyPolicyRoutePath } from '@bcf-v2/routes-statics/common/app/paths/privacy-policy-route-path';
import { responsibleGamingRoutePath } from '@bcf-v2/routes-statics/common/app/paths/responsible-gaming-route-path';
import { sitemapRoutePath } from '@bcf-v2/routes-statics/common/app/paths/sitemap-route-path';
import { termsRoutePath } from '@bcf-v2/routes-statics/common/app/paths/terms-route-path';
import { slotsRoutePath } from '@bcf-v2/routes/casino/app/paths/slots-route-path';
import { FooterLink } from '../types';

export const footerLinksFn = (): FooterLink[] => [
  {
    url: aboutUsRoutePath(),
    title: $localize`About us`
  },
  {
    url: slotsRoutePath(),
    title: $localize`Best Slots Games`
  },
  {
    url: mobileApplicationRoutePath(),
    title: $localize`Mobile Application`
  },
  {
    url: termsRoutePath(),
    title: $localize`Terms and Conditions`
  },
  {
    url: responsibleGamingRoutePath(),
    title: $localize`Responsible Gaming`
  },
  {
    url: privacyPolicyRoutePath(),
    title: $localize`Privacy Policy`
  },
  {
    url: 'https://lvbetpartners.lv/',
    title: $localize`Affiliates`
  },
  {
    url: 'https://casino-blog.lvbet.lv/',
    title: $localize`Casino Blog`
  },
  {
    url: casinoGamesRulesRoutePath(),
    title: $localize`Game rules`
  },
  {
    url: sitemapRoutePath(),
    title: $localize`Sitemap`
  }
];
