import { Provider } from '@angular/core';
import { appMaintenanceUrl } from '@bcf-v2-configurators/tokens/app-maintenance/lvbetlv/app-maintenance-url';
import { APP_MAINTENANCE_URL } from '@bcf-v2-configurators/tokens/app-maintenance/token';
import { bonusesEnabled } from '@bcf-v2-configurators/tokens/bonuses/lvbetlv/bonuses-enabled';
import { BONUSES_ENABLED } from '@bcf-v2-configurators/tokens/bonuses/token';
import { gamesImagesAssetsUrl } from '@bcf-v2-configurators/tokens/casino/lvbetlv/games-images-assets-url';
import { GAMES_IMAGES_ASSETS_URL } from '@bcf-v2-configurators/tokens/casino/token';
import { defaultLang } from '@bcf-v2-configurators/tokens/default-lang/lvbetlv/default-lang';
import { DEFAULT_LANG } from '@bcf-v2-configurators/tokens/default-lang/token';
import { I18N_OPTIMOVE_DESC_LOADER } from '@bcf-v2-configurators/tokens/descs-optimove-i18n/token';
import { I18N_DESC_LOADER } from '@bcf-v2-configurators/tokens/descs/token';
import {
  editProfileAddressAvailableFieldsFn,
  editProfilePrivacyAndNotificationsAvailableFields
} from '@bcf-v2-configurators/tokens/edit-profile/lvbetcom/edit-profile-available-fields';
import {
  EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
  EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS
} from '@bcf-v2-configurators/tokens/edit-profile/token';
import { footerBrandName } from '@bcf-v2-configurators/tokens/footer/lvbetlv/footer-brand-name';
import { footerLinksFn } from '@bcf-v2-configurators/tokens/footer/lvbetlv/footer-links';
import { FOOTER_BRAND_NAME, FOOTER_LINKS } from '@bcf-v2-configurators/tokens/footer/token';
import { ladeskChatConfig } from '@bcf-v2-configurators/tokens/ladesk-chat/lvbetlv/ladesk-chat-config';
import { LADESK_CHAT_CONFIG } from '@bcf-v2-configurators/tokens/ladesk-chat/token';
import { lvbetcomLanguageSwitcherFlagMap } from '@bcf-v2-configurators/tokens/language-switcher/lvbetcom/lvbetcom-language-switcher-flag-map';
import { LANGUAGE_SWITCHER_FLAG_MAP } from '@bcf-v2-configurators/tokens/language-switcher/token';
import { mobileApplicationAppConfig } from '@bcf-v2-configurators/tokens/mobile-application/casino/lvbetlv/config';
import { MOBILE_APPLICATION_APPS_CONFIG } from '@bcf-v2-configurators/tokens/mobile-application/token';
import { OPTIMOVE_SDK_CONFIG_LOADER } from '@bcf-v2-configurators/tokens/optimove-sdk/tokens';
import { sportsLinkWeb } from '@bcf-v2-configurators/tokens/sports-links/lvbetlv/sports-links';
import { SPORTS_FULL_URL } from '@bcf-v2-configurators/tokens/sports-links/token';
import { topNavbarLogoTitle } from '@bcf-v2-configurators/tokens/top-navbar/casino';
import { TOP_NAVBAR_LOGO_TITLE } from '@bcf-v2-configurators/tokens/top-navbar/token';

export const settingsProviders: Provider[] = [
  {
    provide: DEFAULT_LANG,
    useValue: defaultLang
  },
  {
    provide: APP_MAINTENANCE_URL,
    useValue: appMaintenanceUrl
  },
  {
    provide: I18N_DESC_LOADER,
    useValue: () =>
      import(
        /* webpackChunkName: "const-i18n-desc" */ '@bcf-v2-configurators/tokens/descs/lvbetlv/mobile-web/i18n-descs'
        // eslint-disable-next-line @typescript-eslint/typedef
      ).then((mod) => mod.i18nDesc)
  },
  {
    provide: GAMES_IMAGES_ASSETS_URL,
    useValue: gamesImagesAssetsUrl
  },
  {
    provide: TOP_NAVBAR_LOGO_TITLE,
    useValue: topNavbarLogoTitle
  },
  {
    provide: EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS,
    useValue: editProfilePrivacyAndNotificationsAvailableFields
  },
  {
    provide: EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
    useValue: editProfileAddressAvailableFieldsFn
  },
  {
    provide: LADESK_CHAT_CONFIG,
    useValue: ladeskChatConfig
  },
  {
    provide: LANGUAGE_SWITCHER_FLAG_MAP,
    useValue: lvbetcomLanguageSwitcherFlagMap
  },
  {
    provide: SPORTS_FULL_URL,
    useValue: sportsLinkWeb
  },
  {
    provide: FOOTER_LINKS,
    useValue: footerLinksFn
  },
  {
    provide: FOOTER_BRAND_NAME,
    useValue: footerBrandName
  },
  {
    provide: BONUSES_ENABLED,
    useValue: bonusesEnabled
  },
  {
    provide: MOBILE_APPLICATION_APPS_CONFIG,
    useValue: mobileApplicationAppConfig
  },
  {
    provide: OPTIMOVE_SDK_CONFIG_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/optimove-sdk/lvbetlv/casino/web/optimove-sdk-config').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.optimoveSdkConfigs
      )
  },
  {
    provide: I18N_OPTIMOVE_DESC_LOADER,
    useValue: () =>
      import('@bcf-v2-configurators/tokens/descs-optimove-i18n/lvbetlv/casino/i18n-optimove-desc').then(
        // eslint-disable-next-line @typescript-eslint/typedef
        (mod) => mod.i18nOptimoveDesc
      )
  }
];
