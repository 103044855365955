import { EnvCms } from '@bcf-v2-api-access/config-tokens/env-cms';
import { EnvCp } from '@bcf-v2-api-access/config-tokens/env-cp';
import { EnvEeCms } from '@bcf-v2-api-access/config-tokens/env-ee-cms';
import { EnvBase } from '@bcf-v2-configs/env-base';
import { device } from '@device/device';
import { environment } from '@env/env';

export const envBase: EnvBase = {
  brandName: environment.brandName,
  availableLangs: environment.langs,
  translationsBrandName: environment.brandI18Name,
  translationsAllowedBucketLangs: environment.langs,
  translationsLangsMap: environment.langsI18nMap,
  cookiesMap: environment.cookiesFromUrlMap
};

export const envCms: EnvCms = {
  cmsApiUrl: environment.cmsHeadlessApiUrl!
};

export const envEeCms: EnvEeCms = {
  cmsApiUrl: environment.cmsApiUrl!,
  cmsBrandNamePrefix: environment.cmsBrandNamePrefix,
  cmsLangPrefix: environment.cmsLangPrefix,
  cmsDevicePrefix: environment.cmsDevicePrefix,
  brandCmsName: environment.brandCmsName,
  cmsHeadlessEnabled: environment.cmsHeadlessEnabled,
  deviceType: device.type,
  overrideForCms: device.overrideForCms
};

export const envCp: EnvCp = {
  userApiUrl: environment.userApiUrl,
  walletApiUrl: environment.walletApiUrl,
  paymentApiUrl: environment.paymentApiUrl,
  cpSocketUrl1: environment.cpSocketUrl1
};
