import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HistoryMemory {
  private _historyUrls: string[] = [];

  public pushState(url: string): void {
    this._historyUrls.push(url);
  }

  public replaceState(url: string): void {
    const lastIndex: number = this._historyUrls.length - 1;
    if (lastIndex >= 0) {
      this._historyUrls[lastIndex] = url;
    } else {
      this._historyUrls[0] = url;
    }
  }

  public clearLast2(): void {
    this._historyUrls.pop();
    this._historyUrls.pop();
  }

  public get historyUrls(): string[] {
    return this._historyUrls.slice();
  }
}
