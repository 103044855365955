import { Environment } from '@bcf-shared-settings/settings/types';
import { environmentBase } from 'configs/environments/casino/lvbetlv/environment.base';

export const environment: Environment = {
  ...environmentBase,
  production: true,
  userApiUrl: 'https://user.lvbet.lv/',
  walletApiUrl: 'https://wallet.lvbet.lv/',
  paymentApiUrl: 'https://payment.lvbet.lv/',
  cmsApiUrl: {
    default: 'https://lvbet.lv/'
  },
  cmsHeadlessApiUrl: 'https://cms.lvbet.lv/',
  shadowStylesUrl: {
    default: 'https://lvbet-cms3.testowaplatforma123.net/shadow-css/'
  },
  cpSocketUrl1: 'wss://ws.lvbet.lv/register'
};
